
export enum ColorTheme {
  ACTION = 'ACTION',
  NONE = 'NONE',
  BLACK = 'BLACK',
  WHITE = 'WHITE',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  BASE = 'BASE',
  ACCENT = 'ACCENT',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
  LIGHT_BASE = 'LIGHT_BASE',
  RESET = 'RESET'
}

export interface UtilColorThemePackage {
  colorThemeColor?: string;
  colorThemeBackgroundColor?: string;
  colorThemeBorderColor?: string;
  colorThemeHoverColor?: string;
  colorThemeHoverBackgroundColor?: string;
  colorThemeDisabledColor?: string;
  colorThemeDisabledBackgroundColor?: string;
  colorThemeDisabledBorderColor?: string;
  secondColorThemeColor?: string;
  secondColorThemeBackgroundColor?: string;
  secondColorThemeBorderColor?: string;
}

export interface UtilColorThemeMergePackage extends UtilColorThemePackage {
  base?: ColorTheme;
}

// FIRST
// MAP FROM "typescript term" TO "CSS Custom Property variable"
export const utilColorThemePackageKeyToCSSPropertyMapForFirst = new Map<keyof UtilColorThemePackage, string>([
  ['colorThemeColor', '--color-theme-color'],
  ['colorThemeBackgroundColor', '--color-theme-background-color'],
  ['colorThemeBorderColor', '--color-theme-border-color'],
  ['colorThemeHoverColor', '--color-theme-hover-color'],
  ['colorThemeHoverBackgroundColor', '--color-theme-hover-background-color'],
  ['colorThemeDisabledColor', '--color-theme-disabled-color'],
  ['colorThemeDisabledBackgroundColor', '--color-theme-disabled-background-color'],
  ['colorThemeDisabledBorderColor', '--color-theme-disabled-border-color'],
]);

// SECOND
// MAP FROM "typescript term" TO "CSS Custom Property variable"
export const utilColorThemePackageKeyToCSSPropertyMapForSecond = new Map<keyof UtilColorThemePackage, string>([
  ['secondColorThemeColor', '--second-color-theme-color'],
  ['secondColorThemeBackgroundColor', '--second-color-theme-background-color'],
  ['secondColorThemeBorderColor', '--second-color-theme-border-color']
]);

export const colorThemeTypeToDefaultPackageMap = new Map<ColorTheme, UtilColorThemePackage>([
  [ColorTheme.ACTION, {
    colorThemeColor: 'var(--kurz-color-grey-dark)',
    colorThemeBackgroundColor: 'var(--kurz-color-base-light)',
    colorThemeBorderColor: 'var(--color-theme-background-color)',
    colorThemeHoverColor: 'var(--color-theme-color)',
    colorThemeHoverBackgroundColor: '#72B3D4',
    colorThemeDisabledColor: 'var(--kurz-color-disabled-text)',
    colorThemeDisabledBackgroundColor: 'var(--kurz-color-grey-light)',
    colorThemeDisabledBorderColor: 'var(--color-theme-disabled-background-color)',
    secondColorThemeColor: 'var(--kurz-color-grey-dark)',
    secondColorThemeBackgroundColor: 'var(--kurz-color-base-light)',
    secondColorThemeBorderColor: 'var(--second-color-theme-background-color)'
  }],
  [ColorTheme.NONE, {
    colorThemeColor: 'var(--kurz-color-text)',
    colorThemeBackgroundColor: 'transparent',
    colorThemeBorderColor: 'var(--color-theme-background-color)',
    colorThemeHoverColor: 'var(--color-theme-color)',
    colorThemeHoverBackgroundColor: 'var(--kurz-color-grey-light)',
    colorThemeDisabledColor: 'var(--kurz-color-disabled-text)',
    colorThemeDisabledBackgroundColor: 'transparent',
    colorThemeDisabledBorderColor: 'var(--color-theme-disabled-background-color)',
    secondColorThemeColor: 'var(--kurz-color-text)',
    secondColorThemeBackgroundColor: 'transparent',
    secondColorThemeBorderColor: 'var(--second-color-theme-background-color)'
  }],
  [ColorTheme.BASE, {
    colorThemeColor: 'white',
    colorThemeBackgroundColor: 'var(--kurz-color-base)',
    colorThemeBorderColor: 'var(--color-theme-color)',
    colorThemeHoverColor: 'var(--color-theme-color)',
    colorThemeHoverBackgroundColor: 'var(--kurz-color-hover-base)',
    colorThemeDisabledColor: 'var(--kurz-color-disabled-text)',
    colorThemeDisabledBackgroundColor: 'var(--kurz-color-grey-light)',
    colorThemeDisabledBorderColor: 'var(--color-theme-disabled-background-color)',
    secondColorThemeColor: 'white',
    secondColorThemeBackgroundColor: 'var(--kurz-color-base)',
    secondColorThemeBorderColor: 'var(--second-color-theme-background-color)'
  }],
  [ColorTheme.PRIMARY, {
    colorThemeColor: 'white',
    colorThemeBackgroundColor: 'var(--kurz-color-primary)',
    colorThemeBorderColor: 'var(--color-theme-background-color)',
    colorThemeHoverColor: 'var(--color-theme-color)',
    colorThemeHoverBackgroundColor: 'var(--kurz-color-hover-base)',
    colorThemeDisabledColor: 'var(--kurz-color-disabled-text)',
    colorThemeDisabledBackgroundColor: 'var(--kurz-color-grey-light)',
    colorThemeDisabledBorderColor: 'var(--color-theme-disabled-background-color)',
    secondColorThemeColor: 'white',
    secondColorThemeBackgroundColor: 'var(--kurz-color-primary)',
    secondColorThemeBorderColor: 'var(--second-color-theme-background-color)'
  }],
  [ColorTheme.SECONDARY, {
    colorThemeColor: 'var(--kurz-color-primary)',
    colorThemeBackgroundColor: 'var(--kurz-color-secondary)',
    colorThemeBorderColor: 'var(--kurz-color-primary)',
    colorThemeHoverColor: 'var(--color-theme-color)',
    colorThemeHoverBackgroundColor: 'var(--kurz-color-hover-secondary)',
    colorThemeDisabledColor: 'var(--kurz-color-disabled-text)',
    colorThemeDisabledBackgroundColor: '#FAF4F7',
    colorThemeDisabledBorderColor: 'var(--color-theme-disabled-background-color)',
    secondColorThemeColor: 'var(--kurz-color-primary)',
    secondColorThemeBackgroundColor: 'var(--kurz-color-secondary)',
    secondColorThemeBorderColor: 'var(--second-color-theme-background-color)'
  }],
  [ColorTheme.ACCENT, {
    colorThemeColor: 'var(--kurz-color-grey-dark)',
    colorThemeBackgroundColor: 'var(--kurz-color-accent)',
    colorThemeBorderColor: 'var(--color-theme-background-color)',
    colorThemeHoverColor: 'var(--color-theme-color)',
    colorThemeHoverBackgroundColor: '#72B3D4',
    colorThemeDisabledColor: 'var(--kurz-color-disabled-text)',
    colorThemeDisabledBackgroundColor: 'var(--kurz-color-grey-light)',
    colorThemeDisabledBorderColor: 'var(--color-theme-disabled-background-color)',
    secondColorThemeColor: 'white',
    secondColorThemeBackgroundColor: 'var(--kurz-color-accent)',
    secondColorThemeBorderColor: 'var(--second-color-theme-background-color)'
  }],
  [ColorTheme.BLACK, {
    colorThemeColor: 'black',
    colorThemeBackgroundColor: 'transparent',
    colorThemeBorderColor: 'var(--color-theme-background-color)',
    colorThemeHoverColor: 'var(--color-theme-color)',
    colorThemeHoverBackgroundColor: 'rgba(0 0 0 / 20%)',
    colorThemeDisabledColor: 'var(--kurz-color-disabled-text)',
    colorThemeDisabledBackgroundColor: 'var(--kurz-color-grey-light)',
    colorThemeDisabledBorderColor: 'var(--color-theme-disabled-background-color)',
    secondColorThemeColor: 'black',
    secondColorThemeBackgroundColor: 'white',
    secondColorThemeBorderColor: 'var(--second-color-theme-background-color)'
  }],
  [ColorTheme.WHITE, {
    colorThemeColor: 'white',
    colorThemeBackgroundColor: 'transparent',
    colorThemeBorderColor: 'var(--color-theme-background-color)',
    colorThemeHoverColor: 'var(--color-theme-color)',
    colorThemeHoverBackgroundColor: 'rgba(255 255 255 / 20%)',
    colorThemeDisabledColor: 'var(--kurz-color-disabled-text)',
    colorThemeDisabledBackgroundColor: 'var(--kurz-color-grey-light)',
    colorThemeDisabledBorderColor: 'var(--color-theme-disabled-background-color)',
    secondColorThemeColor: 'white',
    secondColorThemeBackgroundColor: 'black',
    secondColorThemeBorderColor: 'var(--second-color-theme-background-color)'
  }],
  [ColorTheme.SUCCESS, {
    colorThemeColor: 'white',
    colorThemeBackgroundColor: 'var(--kurz-color-success)',
    colorThemeBorderColor: 'var(--color-theme-background-color)',
    colorThemeHoverColor: 'var(--color-theme-color)',
    colorThemeHoverBackgroundColor: 'var(--color-theme-background-color)',
    colorThemeDisabledColor: 'var(--kurz-color-disabled-text)',
    colorThemeDisabledBackgroundColor: 'var(--kurz-color-grey-light)',
    colorThemeDisabledBorderColor: 'var(--color-theme-disabled-background-color)',
    secondColorThemeColor: 'white',
    secondColorThemeBackgroundColor: 'var(--kurz-color-success)',
    secondColorThemeBorderColor: 'var(--second-color-theme-background-color)'
  }],
  [ColorTheme.DANGER, {
    colorThemeColor: 'white',
    colorThemeBackgroundColor: 'var(--kurz-color-danger)',
    colorThemeBorderColor: 'var(--color-theme-background-color)',
    colorThemeHoverColor: 'var(--color-theme-color)',
    colorThemeHoverBackgroundColor: 'var(--color-theme-background-color)',
    colorThemeDisabledColor: 'var(--kurz-color-disabled-text)',
    colorThemeDisabledBackgroundColor: 'var(--kurz-color-grey-light)',
    colorThemeDisabledBorderColor: 'var(--color-theme-disabled-background-color)',
    secondColorThemeColor: 'white',
    secondColorThemeBackgroundColor: 'var(--kurz-color-danger)',
    secondColorThemeBorderColor: 'var(--second-color-theme-background-color)'
  }],
  [ColorTheme.LIGHT_BASE, {
    colorThemeColor: 'var(--kurz-color-base)',
    colorThemeBackgroundColor: 'var(--kurz-service-page-background-color)',
    colorThemeBorderColor: 'var(--color-theme-background-color)',
    colorThemeHoverColor: 'var(--color-theme-color)',
    colorThemeHoverBackgroundColor: 'var(--color-theme-background-color)',
    colorThemeDisabledColor: 'var(--kurz-color-disabled-text)',
    colorThemeDisabledBackgroundColor: 'var(--kurz-color-grey-light)',
    colorThemeDisabledBorderColor: 'var(--color-theme-disabled-background-color)',
    secondColorThemeColor: 'var(--kurz-color-base)',
    secondColorThemeBackgroundColor: 'var(--kurz-service-page-background-color)',
    secondColorThemeBorderColor: 'var(--second-color-theme-background-color)'
  }],
  [ColorTheme.RESET, {
    colorThemeColor: 'undefined',
    colorThemeBackgroundColor: 'undefined',
    colorThemeBorderColor: 'undefined',
    colorThemeHoverColor: 'undefined',
    colorThemeHoverBackgroundColor: 'undefined',
    colorThemeDisabledColor: 'undefined',
    colorThemeDisabledBackgroundColor: 'undefined',
    colorThemeDisabledBorderColor: 'undefined',
    secondColorThemeColor: 'undefined',
    secondColorThemeBackgroundColor: 'undefined',
    secondColorThemeBorderColor: 'undefined'
  }]
]);


/**
 * symbolic interface,
 * which shows that the styling of the respective component makes use of the "ColorThemeDirective" input and can
 * be altered by this
 */
// eslint-disable-next-line
export interface ColorThemableComponent {}
