import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { UtilCustomCSSPropertyService } from '../../../services/util-custom-css-property.service';
import { ColorTheme, colorThemeTypeToDefaultPackageMap, UtilColorThemeMergePackage, UtilColorThemePackage, utilColorThemePackageKeyToCSSPropertyMapForFirst, utilColorThemePackageKeyToCSSPropertyMapForSecond } from './theme.enum';



@Directive({
  'selector': '[colorTheme]'
})
export class ColorThemeDirective {

  protected colorThemeComponentClass = '';
  protected secondColorThemeComponentClass = '';
  protected _colorTheme: ColorTheme;
  protected _colorThemePrefix: string = 'color-theme-';
  protected _secondColorTheme: ColorTheme;
  protected _secondColorThemePrefix: string = 'second-color-theme-';


  @HostBinding('class')
  get clazzes(): string[] {
    const arr: string[] = [];

    if (this.colorThemeComponentClass) {
      arr.push(this.colorThemeComponentClass);
    }

    if (this.secondColorThemeComponentClass) {
      arr.push(this.secondColorThemeComponentClass);
    }

    return arr;
  }

  @Input()
  set colorTheme(value: ColorTheme | UtilColorThemeMergePackage | (ColorTheme | UtilColorThemeMergePackage)[]) {

    // is value not an array -> if so them make it to an array
    if (!Array.isArray(value)) {
      value = [value];
    }

    value = value.map<UtilColorThemeMergePackage>(element => {

      let res: UtilColorThemeMergePackage = {};

      if (typeof element === 'string') {
        res.base = (element as ColorTheme);
        const basePackage = colorThemeTypeToDefaultPackageMap.get(res.base) || ({base: 'custom' as ColorTheme} as UtilColorThemePackage);
        Object.assign(res, basePackage);
      } else {
        if (element?.base) {
          const basePackage = colorThemeTypeToDefaultPackageMap.get(element.base) || {};
          element = Object.assign(basePackage, element);
        } else {
          element.base = 'custom' as ColorTheme;
        }
        res = element || res;
      }

      return res;
    });

    const [first, second, merge] = value as UtilColorThemeMergePackage[];

    if (first?.base) {
      this.colorThemeComponentClass = this._colorThemePrefix + this.getPostfix(first.base);
    }

    if (second?.base) {
      this.secondColorThemeComponentClass = this._secondColorThemePrefix + this.getPostfix(second.base);
    }

    Object.assign(first || {}, merge || {});
    Object.assign(second || {}, merge || {});

    this.updateHost('first', first);
    this.updateHost('second', second);
  }

  get colorTheme(): ColorTheme {
    return this._colorTheme;
  }

  constructor(protected elementRef: ElementRef, protected utilCustomCSSPropertyService: UtilCustomCSSPropertyService) {}

  protected getPostfix(value: ColorTheme): string {
    return ((value as string) || 'custom').replace('_', '-').toLowerCase();
  }

  protected updateHost(packageType: 'first' | 'second', pckg: UtilColorThemePackage) {
    const host = this.elementRef?.nativeElement as HTMLElement;

    if (host && pckg) {
      Object.keys(pckg).forEach(key => {
        const map = packageType === 'first' ? utilColorThemePackageKeyToCSSPropertyMapForFirst : utilColorThemePackageKeyToCSSPropertyMapForSecond;
        const cssPropertyName = map.get(key as keyof UtilColorThemePackage);
        if (cssPropertyName) {
          const cssPropertyValue = pckg[key];
          this.utilCustomCSSPropertyService.setValue(cssPropertyName, cssPropertyValue, host);
        }
      });
    }
  }

}
